@import '../colors.scss';

.outline-input
{
    display: inline-block;
    width: 100%;
    min-width: 256px;

    input
    {
        width: 100%;
        display: block;
        height: 56px;
        padding: 0.875rem 1rem;
        line-height: 54px;
        border-radius: 0.25rem;
        border-width: 1px;
        border-style: solid;
        border-color: $grey-light;
        transition: all 75ms ease;

        &[type=date]
        {
            line-height: 1;
        }

        &.has-value
        {
            & + label
            {
                top: -6px;
                font-size: 0.75rem;
            }
        }

        &:hover
        {
            border-color: $grey;

            & + label
            {
                color: $font;
            }
        }

        &:focus
        {
            border-color: $primary;
            border-width: 2px;

            & + label
            {
                top: -6px;
                font-size: 0.75rem;
                color: $primary;
            }
        }
    }

    label
    {
        position: absolute;
        top: 18px;
        left: 0.75rem;
        user-select: none;
        pointer-events: none;
        background-color: $white;
        color: rgba($font, 0.6);
        padding: 0 0.25rem;
        font-size: 1rem;
        transition: all 75ms ease-in-out;
    }
}
