@import '../colors.scss';
@import '../settings.scss';

main[data-view=login]
{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column wrap;
    width: 100vw;
    height: 100vh;

    h1
    {
        font-size: 2rem;
        margin-bottom: 4rem;
        color: $primary;
        text-align: center;
        display: block;
    }

    .signup-cta
    {
        display: block;
        margin-top: 2rem;
        text-align: center;
        font-size: 0.875rem;

        span
        {
            color: rgba($font, 0.6);
            margin: 0 0.35rem;
        }

        a
        {
            color: rgba($primary, 0.87);
            transition: color 75ms $ease;
            font-weight: $medium;

            &:hover
            {
                color: $primary-hover;
            }

            &:active
            {
                color: $primary-active;
            }
        }
    }
}
