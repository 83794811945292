@import '../../../../colors.scss';
@import '../../../../settings.scss';

.user-data-table
{
    display: block;
    width: 100%;
    font-size: 1rem;
    line-height: 1;
    text-align: left;
    overflow-x: scroll;

    .-header
    {
        width: 100%;
        display: inline-table;
        border-bottom: 1px solid $grey-light;
        padding: 0;
        
        span
        {
            font-weight: $medium;
        }
    }

    .-header span
    {
        padding: 0 1rem;
        text-align: left;

        &:nth-child(1)
        {
            padding: 0;
            width: 48px;
            height: 100%;
            display: inline-flex;
            flex-flow: column wrap;
            justify-content: center;
            align-items: center;
        }

        &:nth-child(2)
        {
            width: 100px;
        }

        &:nth-child(3)
        {
            width: 326px;
        }

        &:nth-child(4)
        {
            width: 140px;
        }

        &:nth-child(5)
        {
            width: 100px;
        }
    }

    ul
    {
        display: inline-table;
        width: 100%;

        li
        {
            border-bottom: 1px solid $grey-light;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            height: 52px;
        }
    }

    .-footer,
    .-header-shim
    {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        height: 52px;
        width: 100%;
    }

    .-footer
    {
        justify-content: flex-end;

        div
        {
            margin-left: 1rem;
        }

        span
        {
            color: rgba($font, 0.6);
            font-size: 0.75rem;
        }

        button
        {
            width: 48px;
            height: 48px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            flex-flow: column wrap;
            color: rgba($font, 0.87);
            cursor: pointer;
        }
    }
}
