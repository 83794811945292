@import '../colors.scss';
@import '../settings.scss';

main[data-view=forgotten-password]
{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column wrap;
    width: 100vw;
    height: 100vh;
}
