@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,900&display=swap);
*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  border: none;
  outline: none;
  position: relative;
  display: inline-block; }

html, body, div, span, button, applet, object, input, textarea, select iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  position: relative;
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: 400;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  outline: none;
  max-width: 100%;
  box-shadow: none;
  text-decoration: none;
  color: inherit;
  background-color: transparent; }

body {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-weight: 400;
  color: #282828;
  background-color: var(--grey-1);
  width: 100vw;
  min-height: 100vh;
  overflow-x: hidden; }

:root {
  font-size: 100%; }

template,
style,
script,
head {
  display: none !important; }

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button,
input[type=number] {
  -webkit-appearance: none;
  -moz-appearance: textfield; }

input[type=color]::-webkit-color-swatch-wrapper,
input[type=color]::-webkit-color-swatch {
  padding: 0;
  border: none; }

body {
  color: #282828;
  background-color: #fff;
  width: 100vw;
  min-height: 100vh;
  overflow-x: hidden; }

main[data-view=login] {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column wrap;
  width: 100vw;
  height: 100vh; }
  main[data-view=login] h1 {
    font-size: 2rem;
    margin-bottom: 4rem;
    color: #54C3BB;
    text-align: center;
    display: block; }
  main[data-view=login] .signup-cta {
    display: block;
    margin-top: 2rem;
    text-align: center;
    font-size: 0.875rem; }
    main[data-view=login] .signup-cta span {
      color: rgba(40, 40, 40, 0.6);
      margin: 0 0.35rem; }
    main[data-view=login] .signup-cta a {
      color: rgba(84, 195, 187, 0.87);
      -webkit-transition: color 75ms cubic-bezier(0.4, 0, 0.2, 1);
      transition: color 75ms cubic-bezier(0.4, 0, 0.2, 1);
      font-weight: 500; }
      main[data-view=login] .signup-cta a:hover {
        color: #68cac3; }
      main[data-view=login] .signup-cta a:active {
        color: #4abfb8; }

main[data-view=login] footer,
main[data-view=forgotten-password] footer,
main[data-view=reset-email-sent] footer,
main[data-view=register] footer {
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  white-space: nowrap; }
  main[data-view=login] footer a,
  main[data-view=login] footer span,
  main[data-view=forgotten-password] footer a,
  main[data-view=forgotten-password] footer span,
  main[data-view=reset-email-sent] footer a,
  main[data-view=reset-email-sent] footer span,
  main[data-view=register] footer a,
  main[data-view=register] footer span {
    display: inline-block;
    margin: 0 0.5rem; }

footer {
  line-height: 1.5rem;
  font-size: 0.75rem;
  margin: 0 auto;
  text-align: center;
  white-space: nowrap;
  display: block;
  text-align: center;
  width: 100vw;
  padding: 2rem 0; }
  @media (min-width: 1280px) {
    footer {
      text-align: left;
      -webkit-transform: none;
              transform: none;
      white-space: normal;
      position: fixed;
      bottom: 2rem;
      left: 2.5rem; } }
  footer a,
  footer span {
    margin: 0 0.5rem;
    color: rgba(40, 40, 40, 0.6);
    -webkit-transition: color 75ms ease-in-out;
    transition: color 75ms ease-in-out; }
    @media (min-width: 1280px) {
      footer a,
      footer span {
        display: block;
        margin: 0; } }
  footer a:hover {
    color: #54C3BB; }

.login-form {
  width: calc(100% - 2rem);
  display: grid;
  margin: 0 1rem;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  border-radius: 0.5rem; }
  @media (min-width: 768px) {
    .login-form {
      border: 1px solid #D4D4D4;
      padding: 2rem; } }
  @media (min-width: 620px) {
    .login-form {
      margin: 0 auto;
      width: 420px; } }
  .login-form div.-space-between {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .login-form div.-space-between a {
      font-size: 0.75rem;
      color: rgba(40, 40, 40, 0.6);
      font-weight: 500;
      -webkit-transition: all 75ms cubic-bezier(0.4, 0, 0.2, 1);
      transition: all 75ms cubic-bezier(0.4, 0, 0.2, 1); }
      .login-form div.-space-between a:hover {
        color: #54C3BB; }

.outline-input {
  display: inline-block;
  width: 100%;
  min-width: 256px; }
  .outline-input input {
    width: 100%;
    display: block;
    height: 56px;
    padding: 0.875rem 1rem;
    line-height: 54px;
    border-radius: 0.25rem;
    border-width: 1px;
    border-style: solid;
    border-color: #D4D4D4;
    -webkit-transition: all 75ms ease;
    transition: all 75ms ease; }
    .outline-input input[type=date] {
      line-height: 1; }
    .outline-input input.has-value + label {
      top: -6px;
      font-size: 0.75rem; }
    .outline-input input:hover {
      border-color: #707070; }
      .outline-input input:hover + label {
        color: #282828; }
    .outline-input input:focus {
      border-color: #54C3BB;
      border-width: 2px; }
      .outline-input input:focus + label {
        top: -6px;
        font-size: 0.75rem;
        color: #54C3BB; }
  .outline-input label {
    position: absolute;
    top: 18px;
    left: 0.75rem;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    pointer-events: none;
    background-color: #fff;
    color: rgba(40, 40, 40, 0.6);
    padding: 0 0.25rem;
    font-size: 1rem;
    -webkit-transition: all 75ms ease-in-out;
    transition: all 75ms ease-in-out; }

.g-button {
  display: inline-block;
  height: 36px;
  line-height: 36px;
  border-radius: 0.125rem;
  padding: 0 1rem;
  font-size: 0.875rem;
  font-weight: 500;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  text-align: center;
  min-width: 76px;
  text-transform: uppercase;
  -webkit-transition: all 75ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: all 75ms cubic-bezier(0.4, 0, 0.2, 1); }
  .g-button.-solid.-primary {
    border: 1px solid #54C3BB;
    background-color: #54C3BB;
    color: #fff; }
    .g-button.-solid.-primary:hover {
      border-color: #68cac3;
      background-color: #68cac3; }
    .g-button.-solid.-primary:active {
      border-color: #4abfb8;
      background-color: #4abfb8; }
  .g-button.-grey.-outline {
    border: 1px solid #D4D4D4;
    color: rgba(40, 40, 40, 0.6); }
    .g-button.-grey.-outline:hover {
      background-color: rgba(112, 112, 112, 0.05);
      color: rgba(40, 40, 40, 0.87);
      border-color: #707070; }
    .g-button.-grey.-outline:hover {
      background-color: rgba(112, 112, 112, 0.1);
      color: rgba(40, 40, 40, 0.87);
      border-color: #707070; }
  .g-button.-grey.-text {
    color: #707070;
    padding: 0 0.5rem; }
    .g-button.-grey.-text:hover {
      background-color: rgba(112, 112, 112, 0.05); }
    .g-button.-grey.-text:active {
      background-color: rgba(112, 112, 112, 0.1); }
  .g-button.-danger.-solid {
    border: 1px solid #F86A6A;
    background-color: #F86A6A;
    color: #fff; }
    .g-button.-danger.-solid:hover {
      border-color: #f98080;
      background-color: #f98080; }
    .g-button.-danger.-solid:active {
      border-color: #f75959;
      background-color: #f75959; }
  .g-button.-danger.-outline {
    border: 1px solid #F86A6A;
    color: rgba(248, 106, 106, 0.87); }
    .g-button.-danger.-outline:hover {
      background-color: rgba(248, 106, 106, 0.075);
      color: #f86a6a;
      border-color: #F86A6A; }
    .g-button.-danger.-outline:active {
      background-color: rgba(248, 106, 106, 0.15);
      color: #f86a6a;
      border-color: #F86A6A; }
  .g-button.-danger.-text {
    padding: 0 0.5rem;
    color: #F86A6A; }
    .g-button.-danger.-text:hover {
      background-color: rgba(248, 106, 106, 0.075); }
    .g-button.-danger.-text:active {
      background-color: rgba(248, 106, 106, 0.15); }
  .g-button.is-disabled {
    border-color: #F4F4F4 !important;
    color: #D4D4D4 !important;
    background-color: #F4F4F4 !important;
    cursor: not-allowed !important; }

main[data-view=dashboard] {
  display: block;
  width: 100vw;
  min-height: 100vh;
  padding-top: calc(80px + 4rem); }

.home-view {
  max-width: 100%;
  display: block;
  margin: 0 auto;
  width: 800px; }
  @media (min-width: 1800px) {
    .home-view {
      width: 1024px; } }
  .home-view h1 {
    font-size: 2rem;
    opacity: 0.87;
    display: block;
    text-align: center;
    margin-bottom: 0.5rem; }
  .home-view p {
    opacity: 0.6;
    font-size: 0.875rem;
    text-align: center;
    display: block; }

.activity-overview {
  display: grid;
  width: 100%;
  grid-gap: 2rem;
  gap: 2rem;
  grid-template-columns: minmax(1px, 1fr);
  margin: 0 auto;
  padding: 4rem 2rem; }
  @media (min-width: 1600px) {
    .activity-overview {
      padding: 4rem 0;
      grid-template-columns: 1fr 1fr; } }
  .activity-overview .-child {
    display: block;
    width: 100%;
    border: 1px solid #F4F4F4;
    border-radius: 0.5rem;
    -webkit-transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1); }
    .activity-overview .-child:hover {
      border-color: #D4D4D4;
      box-shadow: 0 4px 16px rgba(40, 40, 40, 0.1); }
    .activity-overview .-child header {
      opacity: 0.87;
      font-size: 1.25rem;
      font-weight: 500;
      padding: 1rem 1rem 0; }
    .activity-overview .-child .-flex-start {
      padding: 0 1rem 1rem;
      display: flex;
      flex-flow: row nowrap; }

.activity-table {
  display: block;
  margin: 0.5rem 0; }

.activity-table-line-item {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 52px;
  line-height: 21px;
  padding: 0 1rem;
  color: rgba(40, 40, 40, 0.87);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  -webkit-transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1); }
  .activity-table-line-item:hover {
    background-color: rgba(40, 40, 40, 0.05); }
  .activity-table-line-item svg {
    color: rgba(40, 40, 40, 0.6);
    margin-right: 1rem; }

.content-modal {
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) scale(0.87);
          transform: translate(-50%, -50%) scale(0.87);
  padding: 1rem;
  width: 640px;
  max-width: calc(100vw - 2rem);
  border-radius: 0.25rem;
  box-shadow: 0 1rem 2rem rgba(40, 40, 40, 0.25);
  background-color: #fff;
  -webkit-animation: modalPop 75ms cubic-bezier(0, 0, 0.2, 1) forwards;
          animation: modalPop 75ms cubic-bezier(0, 0, 0.2, 1) forwards; }
  .content-modal header {
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 1rem;
    display: block; }
  .content-modal .-content-wrapper {
    display: block;
    width: 100%;
    margin-bottom: 1rem; }
  .content-modal .-flex-end {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end; }
  .content-modal .-circle-loader {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    position: absolute; }
    .content-modal .-circle-loader .-circle {
      display: block;
      height: 3rem;
      width: 3rem;
      box-sizing: border-box;
      margin: 0 auto 1rem;
      border: 3px #F4F4F4 solid;
      border-top: 3px #54C3BB solid;
      border-radius: 50%;
      -webkit-animation: loadingSpinner 1s infinite linear;
              animation: loadingSpinner 1s infinite linear; }

.content-modal-backdrop {
  width: 100vw;
  height: 100vh;
  background-color: rgba(40, 40, 40, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  -webkit-animation: fadeModalBackground 600ms cubic-bezier(0, 0, 0.2, 1) forwards;
          animation: fadeModalBackground 600ms cubic-bezier(0, 0, 0.2, 1) forwards; }

@-webkit-keyframes fadeModalBackground {
  from {
    background-color: transparent; }
  to {
    background-color: rgba(40, 40, 40, 0.3); } }

@keyframes fadeModalBackground {
  from {
    background-color: transparent; }
  to {
    background-color: rgba(40, 40, 40, 0.3); } }

@-webkit-keyframes modalPop {
  from {
    -webkit-transform: translate(-50%, -40%) scale(0.87);
            transform: translate(-50%, -40%) scale(0.87);
    opacity: 0; }
  to {
    -webkit-transform: translate(-50%, -50%) scale(1);
            transform: translate(-50%, -50%) scale(1);
    opacity: 1; } }

@keyframes modalPop {
  from {
    -webkit-transform: translate(-50%, -40%) scale(0.87);
            transform: translate(-50%, -40%) scale(0.87);
    opacity: 0; }
  to {
    -webkit-transform: translate(-50%, -50%) scale(1);
            transform: translate(-50%, -50%) scale(1);
    opacity: 1; } }

@-webkit-keyframes loadingSpinner {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg); } }

@keyframes loadingSpinner {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg); } }

.video-content {
  display: block;
  margin-bottom: 1rem;
  width: 100%;
  height: 420px;
  overflow-y: scroll;
  box-shadow: inset 0 0 4px rgba(40, 40, 40, 0.1);
  border: 1px solid #D4D4D4;
  padding: 1rem;
  border-radius: 0.25rem; }
  .video-content .-video-shim {
    display: block;
    width: 100%; }
    .video-content .-video-shim::before {
      content: "";
      width: 100%;
      display: block;
      padding-bottom: 56.25%; }
    .video-content .-video-shim iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
  .video-content p {
    margin-top: 1rem;
    opacity: 0.6;
    line-height: 1.5rem; }

.article-content {
  display: block;
  margin-bottom: 1rem;
  width: 100%;
  height: 320px;
  overflow-y: auto;
  border: 1px solid #D4D4D4;
  border-radius: 0.5rem;
  padding: 1rem;
  box-shadow: inset 0 0 4px rgba(40, 40, 40, 0.1); }
  .article-content p,
  .article-content img {
    margin-bottom: 1rem;
    width: 100%;
    display: block; }
    .article-content p:last-child,
    .article-content img:last-child {
      margin-bottom: 0; }
  .article-content p {
    color: rgba(40, 40, 40, 0.6);
    line-height: 1.5rem;
    margin-bottom: 1rem; }
  .article-content img {
    border-radius: 0.5rem;
    background-color: #F4F4F4;
    object-fit: cover;
    height: 320px;
    margin: 2rem 0; }

.poll-content {
  display: block;
  margin-bottom: 1rem;
  width: 100%;
  height: 320px;
  border: 1px solid #D4D4D4;
  border-radius: 0.5rem;
  padding: 1rem;
  overflow-y: auto;
  box-shadow: inset 0 0 4px rgba(40, 40, 40, 0.1); }
  .poll-content dl {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    gap: 1rem; }
    .poll-content dl div {
      border: 1px solid #D4D4D4;
      border-radius: 0.25rem;
      padding: 1rem; }
    .poll-content dl dt {
      color: rgba(40, 40, 40, 0.87);
      font-weight: 500;
      font-size: 1.125rem;
      line-height: 1.5rem;
      margin-bottom: 0.5rem;
      display: block; }
    .poll-content dl dd {
      display: block;
      color: #54C3BB; }

.dashboard-header {
  display: flex;
  position: fixed;
  top: 0;
  z-index: 1000;
  left: 0;
  width: 100vw;
  height: 80px;
  padding: 0 1rem;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 3px 6px rgba(40, 40, 40, 0.1); }
  @media (min-width: 1280px) {
    .dashboard-header {
      padding: 0 4rem; } }
  .dashboard-header svg {
    -webkit-transform: translateY(-3px);
            transform: translateY(-3px); }

.header-user-controller {
  display: inline-block; }
  .header-user-controller button {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    cursor: pointer; }
    .header-user-controller button svg {
      color: rgba(40, 40, 40, 0.3);
      -webkit-transform: translateY(1px);
              transform: translateY(1px); }
  .header-user-controller .-user-image {
    background-color: #F4F4F4;
    font-size: 1.25rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-flow: column wrap;
    width: 3rem;
    height: 3rem;
    margin-right: 0.5rem;
    border-radius: 50%; }
    .header-user-controller .-user-image span {
      color: rgba(40, 40, 40, 0.3);
      font-weight: 500; }
  .header-user-controller .-modal {
    position: absolute;
    top: calc(100% + 0.5rem);
    display: inline-table;
    right: 0;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(40, 40, 40, 0.1);
    border: 1px solid #F4F4F4; }
    .header-user-controller .-modal .-user-details {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      padding: 1rem;
      border-bottom: 1px solid #F4F4F4; }
    .header-user-controller .-modal .-user-info {
      display: flex;
      flex: 1 1;
      flex-flow: column wrap;
      -webkit-transform: translateY(-2px);
              transform: translateY(-2px);
      margin-left: 0.5rem; }
      .header-user-controller .-modal .-user-info span {
        display: block; }
        .header-user-controller .-modal .-user-info span:first-of-type {
          font-weight: 500;
          margin-bottom: 0.25rem;
          opacity: 0.87; }
        .header-user-controller .-modal .-user-info span:last-of-type {
          font-size: 0.75rem;
          opacity: 0.6; }
    .header-user-controller .-modal nav {
      display: block;
      padding: 0.5rem 0; }
      .header-user-controller .-modal nav a,
      .header-user-controller .-modal nav button {
        display: flex;
        align-items: center;
        flex-flow: row nowrap;
        width: 100%;
        color: rgba(40, 40, 40, 0.6);
        height: 42px;
        line-height: 42px;
        padding: 0 1rem;
        text-align: left;
        -webkit-transition: all 75ms cubic-bezier(0.4, 0, 0.2, 1);
        transition: all 75ms cubic-bezier(0.4, 0, 0.2, 1); }
        .header-user-controller .-modal nav a.-mobile svg,
        .header-user-controller .-modal nav button.-mobile svg {
          width: 18px;
          height: 18px; }
        @media (min-width: 1280px) {
          .header-user-controller .-modal nav a.-mobile,
          .header-user-controller .-modal nav button.-mobile {
            display: none; } }
        .header-user-controller .-modal nav a:hover,
        .header-user-controller .-modal nav button:hover {
          background-color: rgba(84, 195, 187, 0.07);
          color: #54C3BB; }
          .header-user-controller .-modal nav a:hover svg,
          .header-user-controller .-modal nav button:hover svg {
            color: #54C3BB; }
        .header-user-controller .-modal nav a:active,
        .header-user-controller .-modal nav button:active {
          background-color: rgba(84, 195, 187, 0.1);
          color: #54C3BB; }
          .header-user-controller .-modal nav a:active svg,
          .header-user-controller .-modal nav button:active svg {
            color: #54C3BB; }
        .header-user-controller .-modal nav a svg,
        .header-user-controller .-modal nav button svg {
          margin-right: 0.75rem;
          color: rgba(40, 40, 40, 0.6);
          -webkit-transform: none;
                  transform: none; }

.dashboard-navigation {
  position: fixed;
  top: calc(80px + 4rem);
  left: 0;
  display: none; }
  @media (min-width: 1280px) {
    .dashboard-navigation {
      display: inline-block; } }
  .dashboard-navigation a {
    display: block;
    height: 56px;
    padding: 0 3rem 0 2rem;
    color: rgba(40, 40, 40, 0.6);
    font-size: 1.25rem;
    line-height: 56px;
    position: relative;
    overflow: hidden;
    -webkit-transition: color 75ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: color 75ms cubic-bezier(0.4, 0, 0.2, 1); }
    .dashboard-navigation a::before {
      content: "";
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(40, 40, 40, 0.05);
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
      border-radius: 0 28px 28px 0;
      -webkit-transition: all 150ms cubic-bezier(0.4, 0, 0.6, 1);
      transition: all 150ms cubic-bezier(0.4, 0, 0.6, 1);
      z-index: -1; }
    .dashboard-navigation a:hover {
      color: rgba(40, 40, 40, 0.87); }
      .dashboard-navigation a:hover::before {
        -webkit-transform: translateX(0);
                transform: translateX(0);
        -webkit-transition: all 150ms cubic-bezier(0, 0, 0.2, 1);
        transition: all 150ms cubic-bezier(0, 0, 0.2, 1); }
    .dashboard-navigation a.is-active {
      color: #54C3BB; }
      .dashboard-navigation a.is-active::before {
        background-color: rgba(84, 195, 187, 0.1);
        -webkit-transform: translateX(0);
                transform: translateX(0); }

.data-view {
  max-width: 100%;
  display: block;
  margin: 0 auto;
  width: 800px; }
  @media (min-width: 1800px) {
    .data-view {
      width: 1024px; } }
  .data-view h1 {
    font-size: 2rem;
    opacity: 0.87;
    display: block;
    text-align: center;
    margin-bottom: 0.5rem;
    text-transform: capitalize; }
  .data-view nav {
    display: grid;
    grid-gap: 2rem;
    gap: 2rem;
    grid-template-columns: minmax(1px, 1fr);
    width: 640px;
    max-width: 100%;
    margin: 4rem auto;
    padding: 0 4rem; }
    @media (min-width: 800px) {
      .data-view nav {
        padding: 0;
        grid-template-columns: 1fr 1fr; } }
    .data-view nav a {
      height: 52px;
      line-height: 52px;
      padding: 0 1rem;
      border-radius: 0.25rem;
      border: 1px solid #D4D4D4;
      font-size: 1.125rem;
      color: rgba(40, 40, 40, 0.87);
      -webkit-transition: all 75ms cubic-bezier(0.4, 0, 0.2, 1);
      transition: all 75ms cubic-bezier(0.4, 0, 0.2, 1); }
      .data-view nav a:hover {
        border-color: #54C3BB;
        background-color: rgba(84, 195, 187, 0.07);
        color: #54C3BB; }
      .data-view nav a:active {
        border-color: #54C3BB;
        background-color: rgba(84, 195, 187, 0.1);
        color: #54C3BB; }

.user-data-view {
  max-width: 100%;
  display: block;
  margin: 0 auto;
  width: 800px;
  padding: 0 1rem; }
  @media (min-width: 1800px) {
    .user-data-view {
      padding: 0;
      width: 1024px; } }
  .user-data-view h1 {
    font-size: 2rem;
    opacity: 0.87;
    display: block;
    line-height: 1.618;
    text-align: center;
    margin-bottom: 0.5rem; }

.data-manager-user-switcher {
  padding: 0 56px 0 0.5rem; }
  .data-manager-user-switcher select {
    font-size: 2rem;
    opacity: 0.87;
    background: transparent;
    outline: none;
    border: none;
    box-shadow: none;
    -webkit-appearance: none;
    cursor: pointer; }
    .data-manager-user-switcher select::-ms-expand {
      display: none; }
  .data-manager-user-switcher svg {
    position: absolute;
    top: 50%;
    right: calc(1rem + 12px);
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    opacity: 0.6; }

.user-data-manager {
  display: block;
  width: 740px;
  max-width: 100%;
  margin: 4rem auto;
  border: 1px solid #D4D4D4;
  border-radius: 0.25rem; }
  .user-data-manager header {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid #D4D4D4; }
    @media (min-width: 768px) {
      .user-data-manager header {
        justify-content: space-between;
        flex-flow: row nowrap; } }
    .user-data-manager header span {
      font-size: 1rem;
      opacity: 0.87;
      font-weight: 500;
      text-align: left;
      display: block;
      width: 100%; }
    .user-data-manager header div {
      line-height: 0;
      display: block;
      width: 100%;
      text-align: left;
      margin-top: 1rem; }
      @media (min-width: 768px) {
        .user-data-manager header div {
          margin-top: 0;
          text-align: right; } }
    .user-data-manager header button {
      margin-right: 1rem; }
      @media (min-width: 768px) {
        .user-data-manager header button {
          margin-right: 0;
          margin-left: 1rem; } }

.confirmation-modal {
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) scale(0.87);
          transform: translate(-50%, -50%) scale(0.87);
  width: 420px;
  max-width: 100vw;
  border-radius: 0.25rem;
  box-shadow: 0 1rem 2rem rgba(40, 40, 40, 0.25);
  background-color: #fff;
  -webkit-animation: modalPop 75ms cubic-bezier(0, 0, 0.2, 1) forwards;
          animation: modalPop 75ms cubic-bezier(0, 0, 0.2, 1) forwards; }
  .confirmation-modal header {
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 1rem;
    display: block;
    padding: 1.5rem 1.5rem 0; }
  .confirmation-modal p {
    padding: 0 1.5rem;
    opacity: 0.6;
    line-height: 1.5rem;
    margin-bottom: 1rem; }
  .confirmation-modal .-flex-end {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    padding: 0 1rem 1rem; }
  .confirmation-modal button {
    margin-left: 0.5rem; }

.confirmation-modal-backdrop {
  width: 100vw;
  height: 100vh;
  background-color: rgba(40, 40, 40, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  -webkit-animation: fadeModalBackground 600ms cubic-bezier(0, 0, 0.2, 1) forwards;
          animation: fadeModalBackground 600ms cubic-bezier(0, 0, 0.2, 1) forwards; }

.user-data-table {
  display: block;
  width: 100%;
  font-size: 1rem;
  line-height: 1;
  text-align: left;
  overflow-x: scroll; }
  .user-data-table .-header {
    width: 100%;
    display: inline-table;
    border-bottom: 1px solid #D4D4D4;
    padding: 0; }
    .user-data-table .-header span {
      font-weight: 500; }
  .user-data-table .-header span {
    padding: 0 1rem;
    text-align: left; }
    .user-data-table .-header span:nth-child(1) {
      padding: 0;
      width: 48px;
      height: 100%;
      display: inline-flex;
      flex-flow: column wrap;
      justify-content: center;
      align-items: center; }
    .user-data-table .-header span:nth-child(2) {
      width: 100px; }
    .user-data-table .-header span:nth-child(3) {
      width: 326px; }
    .user-data-table .-header span:nth-child(4) {
      width: 140px; }
    .user-data-table .-header span:nth-child(5) {
      width: 100px; }
  .user-data-table ul {
    display: inline-table;
    width: 100%; }
    .user-data-table ul li {
      border-bottom: 1px solid #D4D4D4;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      height: 52px; }
  .user-data-table .-footer,
  .user-data-table .-header-shim {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    height: 52px;
    width: 100%; }
  .user-data-table .-footer {
    justify-content: flex-end; }
    .user-data-table .-footer div {
      margin-left: 1rem; }
    .user-data-table .-footer span {
      color: rgba(40, 40, 40, 0.6);
      font-size: 0.75rem; }
    .user-data-table .-footer button {
      width: 48px;
      height: 48px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      flex-flow: column wrap;
      color: rgba(40, 40, 40, 0.87);
      cursor: pointer; }

.activity-list-item {
  display: block;
  width: 100%; }
  .activity-list-item span {
    padding: 0 1rem;
    text-align: left;
    white-space: nowrap; }
    .activity-list-item span:nth-child(1) {
      padding: 0;
      width: 48px;
      height: 100%;
      display: inline-flex;
      flex-flow: column wrap;
      justify-content: center;
      align-items: center; }
    .activity-list-item span:nth-child(2) {
      width: 100px; }
    .activity-list-item span:nth-child(3) {
      width: 326px; }
    .activity-list-item span:nth-child(4) {
      width: 140px; }
    .activity-list-item span:nth-child(5) {
      width: 100px; }

.checkbox-input {
  display: inline-block; }
  .checkbox-input input {
    width: 0;
    height: 0;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: -9999px;
    left: -9999px; }
    .checkbox-input input:checked + label .-checkbox {
      border-color: #54C3BB;
      background-color: #54C3BB; }
      .checkbox-input input:checked + label .-checkbox svg path {
        stroke-dashoffset: 0;
        -webkit-transition: all 150ms 75ms cubic-bezier(0, 0, 0.2, 1);
        transition: all 150ms 75ms cubic-bezier(0, 0, 0.2, 1); }
  .checkbox-input label {
    display: flex;
    flex-flow: row nowrap;
    cursor: pointer; }
    .checkbox-input label .-checkbox {
      width: 20px;
      height: 20px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      flex-flow: column wrap;
      color: #fff;
      border: 2px solid #D4D4D4;
      border-radius: 0.25rem;
      -webkit-transition: all 75ms cubic-bezier(0.4, 0, 0.2, 1);
      transition: all 75ms cubic-bezier(0.4, 0, 0.2, 1); }
      .checkbox-input label .-checkbox:hover {
        border-color: #707070; }
        .checkbox-input label .-checkbox:hover::before {
          -webkit-transform: scale(0.87);
                  transform: scale(0.87); }
      .checkbox-input label .-checkbox:active::before {
        -webkit-transform: scale(1);
                transform: scale(1);
        background-color: rgba(84, 195, 187, 0.15); }
      .checkbox-input label .-checkbox::before {
        content: "";
        width: 42px;
        height: 42px;
        display: inline-block;
        position: absolute;
        -webkit-transform: scale(0);
                transform: scale(0);
        -webkit-transform-origin: center;
                transform-origin: center;
        -webkit-transition: all 75ms cubic-bezier(0.4, 0, 0.2, 1);
        transition: all 75ms cubic-bezier(0.4, 0, 0.2, 1);
        border-radius: 50%;
        background-color: rgba(84, 195, 187, 0.07); }
      .checkbox-input label .-checkbox svg path {
        stroke-dasharray: 20;
        stroke-dashoffset: 20;
        -webkit-transition: all 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
        transition: all 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1); }

.family-view {
  max-width: 100%;
  display: block;
  margin: 0 auto;
  width: 800px; }
  @media (min-width: 1800px) {
    .family-view {
      width: 1024px; } }
  .family-view h1 {
    font-size: 2rem;
    opacity: 0.87;
    display: block;
    text-align: center;
    margin-bottom: 0.5rem;
    text-transform: capitalize; }
  .family-view .-two-cols {
    display: grid;
    grid-gap: 2rem;
    gap: 2rem;
    grid-template-columns: minmax(1px, 1fr);
    width: 640px;
    max-width: 100%;
    margin: 4rem auto;
    padding: 0 4rem; }
    @media (min-width: 800px) {
      .family-view .-two-cols {
        padding: 0;
        grid-template-columns: 1fr 1fr; } }

.child-account-manager {
  display: block;
  width: 100%;
  border-radius: 0.5rem;
  border: 1px solid #D4D4D4; }
  .child-account-manager h2 {
    padding: 1rem 1rem 0;
    font-size: 1.25rem;
    font-weight: 500;
    display: block;
    margin-bottom: 0.5rem; }
  .child-account-manager span {
    padding: 0 1rem;
    opacity: 0.6;
    display: block;
    margin-bottom: 0.5rem; }
  .child-account-manager .-flex-end {
    padding: 0 0.5rem 0.5rem;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center; }
    .child-account-manager .-flex-end button {
      margin-left: 0.5rem; }

main[data-view=not-found] {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column wrap;
  width: 100vw;
  height: 100vh; }

main[data-view=forgotten-password] {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column wrap;
  width: 100vw;
  height: 100vh; }

.forgotten-password-form {
  width: 592px;
  display: grid;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  padding: 2rem;
  border: 1px solid #D4D4D4;
  border-radius: 0.5rem; }
  .forgotten-password-form div.-justify-right {
    display: flex;
    justify-content: flex-end;
    align-items: center; }
    .forgotten-password-form div.-justify-right a,
    .forgotten-password-form div.-justify-right button {
      margin-left: 1rem; }
  .forgotten-password-form div a.-button {
    display: inline-block;
    height: 36px;
    line-height: 36px;
    border-radius: 0.125rem;
    text-align: center;
    text-transform: uppercase;
    padding: 0 1rem;
    font-size: 0.875rem;
    font-weight: 500;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: pointer;
    border: 1px solid #D4D4D4;
    color: rgba(40, 40, 40, 0.6);
    -webkit-transition: all 75ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: all 75ms cubic-bezier(0.4, 0, 0.2, 1); }
    .forgotten-password-form div a.-button:hover {
      border-color: #707070;
      background-color: rgba(112, 112, 112, 0.05);
      color: rgba(40, 40, 40, 0.87); }
    .forgotten-password-form div a.-button:active {
      border-color: #707070;
      background-color: rgba(112, 112, 112, 0.1);
      color: rgba(40, 40, 40, 0.87); }
  .forgotten-password-form div h1 {
    font-size: 1.25rem;
    font-weight: 500;
    opacity: 0.87; }

main[data-view=reset-email-sent] {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column wrap;
  width: 100vw;
  height: 100vh; }
  main[data-view=reset-email-sent] div {
    width: 592px;
    display: grid;
    margin: 0 1rem;
    grid-gap: 1rem;
    gap: 1rem;
    padding: 2rem;
    border: 1px solid #D4D4D4;
    border-radius: 0.5rem; }
    main[data-view=reset-email-sent] div h1 {
      font-size: 1.25rem;
      font-weight: 500; }
    main[data-view=reset-email-sent] div p {
      font-size: 0.875rem;
      line-height: 1.5rem;
      opacity: 0.6; }
    main[data-view=reset-email-sent] div a {
      font-weight: 500;
      font-size: 0.75rem;
      color: #54C3BB;
      -webkit-transition: color 75ms cubic-bezier(0.4, 0, 0.2, 1);
      transition: color 75ms cubic-bezier(0.4, 0, 0.2, 1); }
      main[data-view=reset-email-sent] div a:hover {
        color: #68cac3; }
      main[data-view=reset-email-sent] div a:active {
        color: #4abfb8; }

main[data-view=register] {
  display: flex;
  flex-flow: column wrap;
  width: 100vw;
  min-height: 100vh;
  padding: 1rem 0; }
  @media (min-width: 768px) {
    main[data-view=register] {
      align-items: center;
      justify-content: center; } }

.signup-form {
  margin: 0 1rem;
  border-radius: 0.5rem; }
  @media (min-width: 768px) {
    .signup-form {
      border: 1px solid #D4D4D4;
      padding: 2rem; } }
  .signup-form h1 {
    font-weight: 500;
    font-size: 1.25rem;
    opacity: 0.87; }
  .signup-form p {
    font-size: 0.875rem;
    opacity: 0.6; }
  .signup-form a {
    font-size: 0.75rem;
    font-weight: 500;
    color: #54C3BB;
    -webkit-transition: color 75ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: color 75ms cubic-bezier(0.4, 0, 0.2, 1); }
    .signup-form a:hover {
      color: #68cac3; }
    .signup-form a:after {
      color: #4abfb8; }

.form-grid {
  width: 640px;
  max-width: calc(100% - 2rem);
  display: grid;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  grid-template-rows: 1fr;
  margin: 0 auto; }
  .form-grid.-thin {
    width: 480px; }
  .form-grid .-two-cols {
    display: grid;
    grid-template-columns: minmax(1px, 1fr);
    grid-gap: 1.5rem;
    gap: 1.5rem; }
    @media (min-width: 768px) {
      .form-grid .-two-cols {
        grid-template-columns: 1fr 1fr; } }
  .form-grid .-space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: row nowrap; }
  .form-grid .-flex-end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-flow: row nowrap; }

.children-form {
  width: 592px;
  max-width: calc(100% - 2rem);
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
  margin: 0 auto;
  border-radius: 0.5rem; }
  @media (min-width: 768px) {
    .children-form {
      border: 1px solid #D4D4D4;
      padding: 2rem; } }
  .children-form h1 {
    font-size: 1.25rem;
    opacity: 0.87;
    text-transform: capitalize; }
  .children-form .is-hidden {
    display: none !important; }
  .children-form p {
    opacity: 0.6;
    line-height: 1.618;
    font-size: 0.875rem; }
  .children-form div {
    display: block;
    width: 100%; }
    .children-form div.-two-cols {
      display: grid;
      grid-template-columns: minmax(1px, 1fr);
      grid-gap: 1.5rem;
      gap: 1.5rem; }
      @media (min-width: 768px) {
        .children-form div.-two-cols {
          grid-template-columns: 1fr 1fr; } }
    .children-form div.-children-accounts {
      display: grid;
      grid-template-columns: minmax(1px, 1fr);
      grid-gap: 1.5rem;
      gap: 1.5rem; }
      @media (min-width: 768px) {
        .children-form div.-children-accounts {
          grid-template-columns: 1fr 1fr; } }
    .children-form div.-no-accounts {
      display: none; }
    .children-form div.-flex-end {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-flow: row nowrap; }
  .children-form .g-button {
    margin-left: 1rem; }
  .children-form a:not(.g-button) {
    border-radius: 0.5rem;
    border: 1px solid #D4D4D4;
    height: 52px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-flow: row nowrap;
    color: rgba(40, 40, 40, 0.6);
    -webkit-transition: all 75ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: all 75ms cubic-bezier(0.4, 0, 0.2, 1);
    padding: 0 1rem;
    font-size: 0.875rem; }
    .children-form a:not(.g-button):hover {
      color: rgba(40, 40, 40, 0.87);
      border-color: #707070;
      background-color: rgba(112, 112, 112, 0.05); }
    .children-form a:not(.g-button):active {
      color: rgba(40, 40, 40, 0.87);
      border-color: #707070;
      background-color: rgba(112, 112, 112, 0.1); }
    .children-form a:not(.g-button) svg {
      margin-right: 1rem; }

.child-account {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  height: 52px;
  line-height: 52px;
  flex-flow: row nowrap;
  border: 1px solid #D4D4D4;
  color: rgba(40, 40, 40, 0.87);
  border-radius: 0.5rem; }
  .child-account svg {
    cursor: pointer;
    color: rgba(40, 40, 40, 0.3);
    -webkit-transition: color 75ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: color 75ms cubic-bezier(0.4, 0, 0.2, 1); }
    .child-account svg:hover {
      color: rgba(40, 40, 40, 0.6); }
    .child-account svg:active {
      color: rgba(40, 40, 40, 0.87); }

.child-login-form {
  padding: 2rem;
  border: 1px solid #D4D4D4;
  border-radius: 0.5rem; }
  .child-login-form h1 {
    font-size: 1.25rem;
    opacity: 0.87;
    text-align: center; }
  .child-login-form a.-button {
    font-size: 0.875rem;
    border-radius: 0.125rem;
    border: 1px solid #D4D4D4;
    color: rgba(40, 40, 40, 0.6);
    -webkit-transition: all 75ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: all 75ms cubic-bezier(0.4, 0, 0.2, 1);
    height: 36px;
    line-height: 36px;
    -webkit-transform: translateY(1px);
            transform: translateY(1px);
    padding: 0 1rem;
    min-width: 76px;
    margin-right: 1rem;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase; }
    .child-login-form a.-button:hover {
      background-color: rgba(112, 112, 112, 0.05);
      color: rgba(40, 40, 40, 0.87);
      border-color: #707070; }
    .child-login-form a.-button:active {
      background-color: rgba(112, 112, 112, 0.1);
      color: rgba(40, 40, 40, 0.87);
      border-color: #707070; }
  .child-login-form a:not(.-button) {
    font-size: 0.75rem;
    font-weight: 500;
    color: rgba(40, 40, 40, 0.6);
    -webkit-transition: color 75ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: color 75ms cubic-bezier(0.4, 0, 0.2, 1); }
    .child-login-form a:not(.-button):hover {
      color: #68cac3; }
    .child-login-form a:not(.-button):active {
      color: #4abfb8; }

.add-child-account-form {
  grid-gap: 1.5rem;
  gap: 1.5rem;
  padding: 2rem;
  border: 1px solid #D4D4D4;
  border-radius: 0.5rem; }
  .add-child-account-form h1 {
    font-size: 1.25rem;
    opacity: 0.87; }
  .add-child-account-form p {
    opacity: 0.6;
    font-size: 0.875rem;
    line-height: 1.618; }
  .add-child-account-form a:not(.g-button) {
    font-size: 0.75rem;
    font-weight: 500;
    color: #54C3BB;
    -webkit-transition: color 75ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: color 75ms cubic-bezier(0.4, 0, 0.2, 1); }
    .add-child-account-form a:not(.g-button):hover {
      color: #68cac3; }
    .add-child-account-form a:not(.g-button):after {
      color: #4abfb8; }
  .add-child-account-form button {
    margin-left: 1rem; }

.privacy-policy-form {
  width: 640px;
  max-width: calc(100% - 2rem);
  margin: 0 auto;
  border-radius: 0.5rem; }
  @media (min-width: 768px) {
    .privacy-policy-form {
      border: 1px solid #D4D4D4;
      padding: 2rem; } }
  .privacy-policy-form h1 {
    font-size: 1.25rem;
    opacity: 0.87;
    text-transform: capitalize;
    margin-bottom: 1rem; }
  .privacy-policy-form p {
    font-size: 0.875rem;
    line-height: 1.5rem;
    opacity: 0.6;
    margin-bottom: 1rem; }
  .privacy-policy-form .-flex-end {
    display: flex;
    justify-content: flex-end;
    flex-flow: row;
    align-items: center; }
  .privacy-policy-form button {
    margin-left: 1rem; }

.policy-preview {
  border-radius: 0.5rem;
  border: 1px solid #D4D4D4;
  display: block;
  width: 100%;
  margin-bottom: 2.5rem;
  padding: 1rem; }
  .policy-preview .-window {
    width: 100%;
    height: 380px;
    overflow-y: scroll;
    font-size: 0.75rem;
    line-height: 1.313rem;
    scroll-behavior: smooth; }
  .policy-preview button {
    background-color: #54C3BB;
    box-shadow: 0 1px 3px rgba(40, 40, 40, 0.15);
    position: absolute;
    right: 1rem;
    bottom: -1.5rem;
    color: #fff;
    display: inline-flex;
    width: 3rem;
    height: 3rem;
    justify-content: center;
    align-items: center;
    flex-flow: column wrap;
    border-radius: 50%;
    cursor: pointer; }
    .policy-preview button svg {
      -webkit-transform: translateY(2px);
              transform: translateY(2px); }

.terms-of-use-form {
  width: 640px;
  max-width: calc(100% - 2rem);
  margin: 0 auto;
  border-radius: 0.5rem; }
  @media (min-width: 768px) {
    .terms-of-use-form {
      border: 1px solid #D4D4D4;
      padding: 2rem; } }
  .terms-of-use-form h1 {
    font-size: 1.25rem;
    opacity: 0.87;
    text-transform: capitalize;
    margin-bottom: 1rem; }
  .terms-of-use-form p {
    font-size: 0.875rem;
    line-height: 1.5rem;
    opacity: 0.6;
    margin-bottom: 1rem; }
  .terms-of-use-form .-flex-end {
    display: flex;
    justify-content: flex-end;
    flex-flow: row;
    align-items: center; }
  .terms-of-use-form button {
    margin-left: 1rem; }

.copa-form {
  width: 640px;
  max-width: calc(100% - 2rem);
  margin: 0 auto;
  border-radius: 0.5rem; }
  @media (min-width: 768px) {
    .copa-form {
      border: 1px solid #D4D4D4;
      padding: 2rem; } }
  .copa-form h1 {
    font-size: 1.25rem;
    opacity: 0.87;
    text-transform: capitalize;
    margin-bottom: 1rem; }
  .copa-form p {
    font-size: 0.875rem;
    line-height: 1.5rem;
    opacity: 0.6;
    margin-bottom: 1rem; }
  .copa-form .-flex-end {
    display: flex;
    justify-content: flex-end;
    flex-flow: row;
    align-items: center; }
  .copa-form button {
    margin-left: 1rem; }

