@import '../../colors.scss';
@import '../../settings.scss';

.child-login-form
{
    padding: 2rem;
    border: 1px solid $grey-light;
    border-radius: 0.5rem;

    h1
    {
        font-size: 1.25rem;
        opacity: 0.87;
        text-align: center;
    }

    a
    {
        &.-button
        {
            font-size: 0.875rem;
            border-radius: 0.125rem;
            border: 1px solid $grey-light;
            color: rgba($font, 0.6);
            transition: all 75ms $ease;
            height: 36px;
            line-height: 36px;
            transform: translateY(1px);
            padding: 0 1rem;
            min-width: 76px;
            margin-right: 1rem;
            font-weight: $medium;
            text-align: center;
            text-transform: uppercase;

            &:hover
            {
                background-color: $grey-hover-opacity;
                color: rgba($font, 0.87);
                border-color: $grey;
            }

            &:active
            {
                background-color: $grey-active-opacity;
                color: rgba($font, 0.87);
                border-color: $grey;
            }
        }

        &:not(.-button)
        {
            font-size: 0.75rem;
            font-weight: $medium;
            color: rgba($font, 0.6);
            transition: color 75ms $ease;

            &:hover
            {
                color: $primary-hover;
            }

            &:active
            {
                color: $primary-active;
            }
        }
    }
}
