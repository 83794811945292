@import '../../colors.scss';
@import '../../settings.scss';

.video-content
{
    display: block;
    margin-bottom: 1rem;
    width: 100%;
    height: 420px;
    overflow-y: scroll;
    box-shadow: inset 0 0 4px rgba($font, 0.1);
    border: 1px solid $grey-light;
    padding: 1rem;
    border-radius: 0.25rem;

    .-video-shim
    {
        display: block;
        width: 100%;

        &::before
        {
            content: "";
            width: 100%;
            display: block;
            padding-bottom: 56.25%;
        }

        iframe
        {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    p
    {
        margin-top: 1rem;
        opacity: 0.6;
        line-height: 1.5rem;
    }
}
