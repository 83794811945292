@import '../../colors.scss';
@import '../../settings.scss';

.home-view
{
    max-width: 100%;
    display: block;
    margin: 0 auto;
    width: 800px;

    @media (min-width: 1800px)
    {
        width: 1024px;
    }

    h1
    {
        font-size: 2rem;
        opacity: 0.87;
        display: block;
        text-align: center;
        margin-bottom: 0.5rem;
    }

    p
    {
        opacity: 0.6;
        font-size: 0.875rem;
        text-align: center;
        display: block;
    }
}
