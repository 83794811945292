@import '../../colors.scss';
@import '../../settings.scss';

.add-child-account-form
{
    gap: 1.5rem;
    padding: 2rem;
    border: 1px solid $grey-light;
    border-radius: 0.5rem;

    h1
    {
        font-size: 1.25rem;
        opacity: 0.87;
    }

    p
    {
        opacity: 0.6;
        font-size: 0.875rem;
        line-height: 1.618;
    }

    a:not(.g-button)
    {
        font-size: 0.75rem;
        font-weight: $medium;
        color: $primary;
        transition: color 75ms $ease;

        &:hover
        {
            color: $primary-hover;
        }

        &:after
        {
            color: $primary-active;
        }
    }

    button
    {
        margin-left: 1rem;
    }
}
