@import '../../../../../colors.scss';
@import '../../../../../settings.scss';

.activity-list-item
{
    display: block;
    width: 100%;

    span
    {
        padding: 0 1rem;
        text-align: left;
        white-space: nowrap;

        &:nth-child(1)
        {
            padding: 0;
            width: 48px;
            height: 100%;
            display: inline-flex;
            flex-flow: column wrap;
            justify-content: center;
            align-items: center;
        }

        &:nth-child(2)
        {
            width: 100px;
        }

        &:nth-child(3)
        {
            width: 326px;
        }

        &:nth-child(4)
        {
            width: 140px;
        }

        &:nth-child(5)
        {
            width: 100px;
        }
    }
}
