@import '../colors.scss';
@import '../settings.scss';

.content-modal
{
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.87);
    padding: 1rem;
    width: 640px;
    max-width: calc(100vw - 2rem);
    border-radius: 0.25rem;
    box-shadow: 0 1rem 2rem rgba($font, 0.25);
    background-color: $white;
    animation: modalPop 75ms $ease-in forwards;

    header
    {
        font-size: 1.25rem;
        font-weight: $medium;
        margin-bottom: 1rem;
        display: block;
    }

    .-content-wrapper
    {
        display: block;
        width: 100%;
        margin-bottom: 1rem;
    }

    .-flex-end
    {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;
    }

    .-circle-loader
    {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;

        .-circle
        {
            display: block;
            height: 3rem;
            width: 3rem;
            box-sizing: border-box;
            margin: 0 auto 1rem;
            border: 3px $off-white solid;
            border-top: 3px $primary solid;
            border-radius: 50%;
            animation: loadingSpinner 1s infinite linear;
        }
    }
}

.content-modal-backdrop
{
    width: 100vw;
    height: 100vh;
    background-color: rgba($font, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000;
    animation: fadeModalBackground 600ms $ease-in forwards;
}

@keyframes fadeModalBackground
{
    from
    {
        background-color: transparent;
    }
    to
    {
        background-color: rgba($font, 0.3);
    }
}

@keyframes modalPop
{
    from
    {
        transform: translate(-50%, -40%) scale(0.87);
        opacity: 0;
    }
    to
    {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }
}

@keyframes loadingSpinner
{
    from
    {
        transform: rotate(0deg);
    }
    to
    {
        transform: rotate(359deg);
    }
}
