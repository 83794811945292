@import '../colors.scss';
@import '../settings.scss';

main[data-view=register]
{
    display: flex;
    flex-flow: column wrap;
    width: 100vw;
    min-height: 100vh;
    padding: 1rem 0;

    @media (min-width: 768px)
    {
        align-items: center;
        justify-content: center;
    }
}
