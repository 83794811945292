@import '../../../colors.scss';
@import '../../../settings.scss';

.activity-overview
{
    display: grid;
    width: 100%;
    gap: 2rem;
    grid-template-columns: minmax(1px, 1fr);
    margin: 0 auto;
    padding: 4rem 2rem;

    @media (min-width: 1600px)
    {
        padding: 4rem 0;
        grid-template-columns: 1fr 1fr;
    }

    .-child
    {
        display: block;
        width: 100%;
        border: 1px solid $off-white;
        border-radius: 0.5rem;
        transition: all 150ms $ease;

        &:hover
        {
            border-color: $grey-light;
            box-shadow: 0 4px 16px rgba($font, 0.1);
        }

        header
        {
            opacity: 0.87;
            font-size: 1.25rem;
            font-weight: $medium;
            padding: 1rem 1rem 0;
        }

        .-flex-start
        {
            padding: 0 1rem 1rem;
            display: flex;
            flex-flow: row nowrap;
        }
    }
}
