@import '../../../colors.scss';
@import '../../../settings.scss';

.child-account
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    height: 52px;
    line-height: 52px;
    flex-flow: row nowrap;
    border: 1px solid $grey-light;
    color: rgba($font, 0.87);
    border-radius: 0.5rem;

    svg
    {
        cursor: pointer;
        color: rgba($font, 0.3);
        transition: color 75ms $ease;

        &:hover
        {
            color: rgba($font, 0.6);
        }

        &:active
        {
            color: rgba($font, 0.87);
        }
    }
}
