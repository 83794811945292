@import '../../colors.scss';
@import '../../settings.scss';

.terms-of-use-form
{
    width: 640px;
    max-width: calc(100% - 2rem);
    margin: 0 auto;
    border-radius: 0.5rem;

    @media (min-width: 768px)
    {
        border: 1px solid $grey-light;
        padding: 2rem;
    }

    h1
    {
        font-size: 1.25rem;
        opacity: 0.87;
        text-transform: capitalize;
        margin-bottom: 1rem;
    }

    p
    {
        font-size: 0.875rem;
        line-height: 1.5rem;
        opacity: 0.6;
        margin-bottom: 1rem;
    }

    .-flex-end
    {
        display: flex;
        justify-content: flex-end;
        flex-flow: row;
        align-items: center;
    }

    button
    {
        margin-left: 1rem;
    }
}
