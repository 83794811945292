@import './colors.scss';

body
{
    color: $font;
    background-color: $white;
    width: 100vw;
    min-height: 100vh;
    overflow-x: hidden;
}
