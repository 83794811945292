@import '../../../../../colors.scss';
@import '../../../../../settings.scss';

.activity-table-line-item
{
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    height: 52px;
    line-height: 21px;
    padding: 0 1rem;
    color: rgba($font, 0.87);
    user-select: none;
    cursor: pointer;
    transition: all 150ms $ease;

    &:hover
    {
        background-color: $white-hover-opacity;
    }

    svg
    {
        color: rgba($font, 0.6);
        margin-right: 1rem;
    }
}
