@import '../colors.scss';

main[data-view=login],
main[data-view=forgotten-password],
main[data-view=reset-email-sent],
main[data-view=register]
{
    footer
    {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;

        a,
        span
        {
            display: inline-block;
            margin: 0 0.5rem;
        }
    }
}

footer
{
    line-height: 1.5rem;
    font-size: 0.75rem;
    margin: 0 auto;
    text-align: center;
    white-space: nowrap;
    display: block;
    text-align: center;
    width: 100vw;
    padding: 2rem 0;

    @media (min-width: 1280px)
    {
        text-align: left;
        transform: none;
        white-space: normal;
        position: fixed;
        bottom: 2rem;
        left: 2.5rem;       
    }
    
    a,
    span
    {
        margin: 0 0.5rem;
        color: rgba($font, 0.6);
        transition: color 75ms ease-in-out;

        @media (min-width: 1280px)
        {
            display: block;
            margin: 0;
        }
    }

    a
    {
        &:hover
        {
            color: $primary;
        }
    }
}
