@import '../../../colors.scss';
@import '../../../settings.scss';

.child-account-manager
{
    display: block;
    width: 100%;
    border-radius: 0.5rem;
    border: 1px solid $grey-light;

    h2
    {
        padding: 1rem 1rem 0;
        font-size: 1.25rem;
        font-weight: $medium;
        display: block;
        margin-bottom: 0.5rem;
    }

    span
    {
        padding: 0 1rem;
        opacity: 0.6;
        display: block;
        margin-bottom: 0.5rem;
    }

    .-flex-end
    {
        padding: 0 0.5rem 0.5rem;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;
        align-items: center;
        
        button
        {
            margin-left: 0.5rem;
        }
    }
}
