@import '../colors.scss';
@import '../settings.scss';

main[data-view=reset-email-sent]
{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column wrap;
    width: 100vw;
    height: 100vh;

    div
    {
        width: 592px;
        display: grid;
        margin: 0 1rem;
        gap: 1rem;
        padding: 2rem;
        border: 1px solid $grey-light;
        border-radius: 0.5rem;

        h1
        {
            font-size: 1.25rem;
            font-weight: $medium;
        }

        p
        {
            font-size: 0.875rem;
            line-height: 1.5rem;
            opacity: 0.6;
        }

        a
        {
            font-weight: $medium;
            font-size: 0.75rem;
            color: $primary;
            transition: color 75ms $ease;

            &:hover
            {
                color: $primary-hover;
            }

            &:active
            {
                color: $primary-active;
            }
        }
    }
}
