@import '../colors.scss';
@import '../settings.scss';

.confirmation-modal
{
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.87);
    width: 420px;
    max-width: 100vw;
    border-radius: 0.25rem;
    box-shadow: 0 1rem 2rem rgba($font, 0.25);
    background-color: $white;
    animation: modalPop 75ms $ease-in forwards;

    header
    {
        font-size: 1.25rem;
        font-weight: $medium;
        margin-bottom: 1rem;
        display: block;
        padding: 1.5rem 1.5rem 0;
    }

    p
    {
        padding: 0 1.5rem;
        opacity: 0.6;
        line-height: 1.5rem;
        margin-bottom: 1rem;
    }

    .-flex-end
    {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;
        padding: 0 1rem 1rem;
    }

    button
    {
        margin-left: 0.5rem;
    }
}

.confirmation-modal-backdrop
{
    width: 100vw;
    height: 100vh;
    background-color: rgba($font, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000;
    animation: fadeModalBackground 600ms $ease-in forwards;
}
