@import '../../colors.scss';
@import '../../settings.scss';

.user-data-view
{
    max-width: 100%;
    display: block;
    margin: 0 auto;
    width: 800px;
    padding: 0 1rem;

    @media (min-width: 1800px)
    {
        padding: 0;
        width: 1024px;
    }

    h1
    {
        font-size: 2rem;
        opacity: 0.87;
        display: block;
        line-height: 1.618;
        text-align: center;
        margin-bottom: 0.5rem;
    }
}
