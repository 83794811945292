@import '../../../colors.scss';
@import '../../../settings.scss';

.header-user-controller
{
    display: inline-block;

    button
    {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        cursor: pointer;

        svg
        {
            color: rgba($font, 0.3);
            transform: translateY(1px);
        }
    }

    .-user-image
    {
        background-color: $off-white;
        font-size: 1.25rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        flex-flow: column wrap;
        width: 3rem;
        height: 3rem;
        margin-right: 0.5rem;
        border-radius: 50%;

        span
        {
            color: rgba($font, 0.3);
            font-weight: $medium;
        }
    }

    .-modal
    {
        position: absolute;
        top: calc(100% + 0.5rem);
        display: inline-table;
        right: 0;
        background-color: $white;
        box-shadow: 0 2px 8px rgba($font, 0.1);
        border: 1px solid $off-white;

        .-user-details
        {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            padding: 1rem;
            border-bottom: 1px solid $off-white;
        }

        .-user-info
        {
            display: flex;
            flex: 1;
            flex-flow: column wrap;
            transform: translateY(-2px);
            margin-left: 0.5rem;

            span
            {
                display: block;

                &:first-of-type
                {
                    font-weight: $medium;
                    margin-bottom: 0.25rem;
                    opacity: 0.87;
                }

                &:last-of-type
                {
                    font-size: 0.75rem;
                    opacity: 0.6;
                }
            }
        }

        nav
        {
            display: block;
            padding: 0.5rem 0;

            a,
            button
            {
                display: flex;
                align-items: center;
                flex-flow: row nowrap;
                width: 100%;
                color: rgba($font, 0.6);
                height: 42px;
                line-height: 42px;
                padding: 0 1rem;
                text-align: left;
                transition: all 75ms $ease;

                &.-mobile
                {
                    svg
                    {
                        width: 18px;
                        height: 18px;
                    }

                    @media (min-width: 1280px)
                    {
                        display: none;
                    }
                }

                &:hover
                {
                    background-color: $primary-hover-opacity;
                    color: $primary;

                    svg
                    {
                        color: $primary;
                    }
                }

                &:active
                {
                    background-color: $primary-active-opacity;
                    color: $primary;

                    svg
                    {
                        color: $primary;
                    }
                }

                svg
                {
                    margin-right: 0.75rem;
                    color: rgba($font, 0.6);
                    transform: none;
                }
            }
        }
    }
}
