@import '../colors.scss';
@import '../settings.scss';

.policy-preview
{
    border-radius: 0.5rem;
    border: 1px solid $grey-light;
    display: block;
    width: 100%;
    margin-bottom: 2.5rem;
    padding: 1rem;

    .-window
    {
        width: 100%;
        height: 380px;
        overflow-y: scroll;
        font-size: 0.75rem;
        line-height: 1.313rem;
        scroll-behavior: smooth;
    }

    button
    {
        background-color: $primary;
        box-shadow: 0 1px 3px rgba($font, 0.15);
        position: absolute;
        right: 1rem;
        bottom: -1.5rem;
        color: $white;
        display: inline-flex;
        width: 3rem;
        height: 3rem;
        justify-content: center;
        align-items: center;
        flex-flow: column wrap;
        border-radius: 50%;
        cursor: pointer;

        svg
        {
            transform: translateY(2px);
        }
    }
}
