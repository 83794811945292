@import '../../colors.scss';
@import '../../settings.scss';

.family-view
{
    max-width: 100%;
    display: block;
    margin: 0 auto;
    width: 800px;

    @media (min-width: 1800px)
    {
        width: 1024px;
    }

    h1
    {
        font-size: 2rem;
        opacity: 0.87;
        display: block;
        text-align: center;
        margin-bottom: 0.5rem;
        text-transform: capitalize;
    }

    .-two-cols
    {
        display: grid;
        gap: 2rem;
        grid-template-columns: minmax(1px, 1fr);
        width: 640px;
        max-width: 100%;
        margin: 4rem auto;
        padding: 0 4rem;

        @media (min-width: 800px)
        {
            padding: 0;
            grid-template-columns: 1fr 1fr;
        }
    }
}
