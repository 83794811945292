@import '../../colors.scss';
@import '../../settings.scss';

.dashboard-navigation
{    
    position: fixed;
    top: calc(80px + 4rem);
    left: 0;
    display: none;

    @media (min-width: 1280px)
    {
        display: inline-block;
    }

    a
    {
        display: block;
        height: 56px;
        padding: 0 3rem 0 2rem;
        color: rgba($font, 0.6);
        font-size: 1.25rem;
        line-height: 56px;
        position: relative;
        overflow: hidden;
        transition: color 75ms $ease;

        &::before
        {
            content: "";
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba($font, 0.05);
            transform: translateX(-100%);
            border-radius: 0 28px 28px 0;
            transition: all 150ms $ease-sharp;
            z-index: -1;
        }

        &:hover
        {
            color: rgba($font, 0.87);

            &::before
            {
                transform: translateX(0);
                transition: all 150ms $ease-in;
            }   
        }

        &.is-active
        {
            color: $primary;

            &::before
            {
                background-color: $primary-active-opacity;
                transform: translateX(0);
            }
        }
    }
}
