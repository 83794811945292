$font: #282828;
$font-light: #BEBEBE;
$font-medium: #838383;

$white: #fff;
$off-white: #F4F4F4;
$white-hover-opacity: rgba($font, 0.05);

$grey: #707070;
$grey-light: #D4D4D4;
$grey-hover-opacity: rgba($grey, 0.05);
$grey-active-opacity: rgba($grey, 0.1);

$primary: #54C3BB;
$primary-hover: hsl(176, 48%, 60%);
$primary-active: hsl(176, 48%, 52%);
$primary-hover-opacity: rgba($primary, 0.07);
$primary-active-opacity: rgba($primary, 0.1);

$danger: #F86A6A;
$danger-hover: hsl(0, 91%, 74%);
$danger-active: hsl(0, 91%, 66%);
$danger-hover-opacity: rgba(248, 106, 106, 0.075);
$danger-active-opacity: rgba(248, 106, 106, 0.15);
