@import '../../colors.scss';
@import '../../settings.scss';

.signup-form
{
    margin: 0 1rem;
    border-radius: 0.5rem;

    @media (min-width: 768px)
    {
        border: 1px solid $grey-light;
        padding: 2rem;
    }

    h1
    {
        font-weight: $medium;
        font-size: 1.25rem;
        opacity: 0.87;
    }

    p
    {
        font-size: 0.875rem;
        opacity: 0.6;
    }

    a
    {
        font-size: 0.75rem;
        font-weight: $medium;
        color: $primary;
        transition: color 75ms $ease;

        &:hover
        {
            color: $primary-hover;
        }

        &:after
        {
            color: $primary-active;
        }
    }
}
