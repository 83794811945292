@import '../../colors.scss';
@import '../../settings.scss';

.article-content
{
    display: block;
    margin-bottom: 1rem;
    width: 100%;
    height: 320px;
    overflow-y: auto;
    border: 1px solid $grey-light;
    border-radius: 0.5rem;
    padding: 1rem;
    box-shadow: inset 0 0 4px rgba($font, 0.1);

    p,
    img
    {
        margin-bottom: 1rem;
        width: 100%;
        display: block;

        &:last-child
        {
            margin-bottom: 0;
        }
    }

    p
    {
        color: rgba($font, 0.6);
        line-height: 1.5rem;
        margin-bottom: 1rem;
    }

    img
    {
        border-radius: 0.5rem;
        background-color: $off-white;
        object-fit: cover;
        height: 320px;
        margin: 2rem 0;
    }
}
