@import '../colors.scss';
@import '../settings.scss';

.checkbox-input
{
    display: inline-block;

    input
    {
        width: 0;
        height: 0;
        visibility: hidden;
        opacity: 0;
        position: absolute;
        top: -9999px;
        left: -9999px;

        &:checked
        {
            & + label
            {
                .-checkbox
                {
                    border-color: $primary;
                    background-color: $primary;
                    
                    svg
                    {
                        path
                        {
                            stroke-dashoffset: 0;
                            transition: all 150ms 75ms $ease-in;
                        }
                    }
                }
            }
        }
    }

    label
    {
        display: flex;
        flex-flow: row nowrap;
        cursor: pointer;

        .-checkbox
        {
            width: 20px;
            height: 20px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            flex-flow: column wrap;
            color: $white;
            border: 2px solid $grey-light;
            border-radius: 0.25rem;
            transition: all 75ms $ease;

            &:hover
            {
                border-color: $grey;
                
                &::before
                {
                    transform: scale(0.87);
                }
            }

            &:active
            {
                &::before
                {
                    transform: scale(1);
                    background-color: rgba($primary, 0.15);
                }
            }

            &::before
            {
                content: "";
                width: 42px;
                height: 42px;
                display: inline-block;
                position: absolute;
                transform: scale(0);
                transform-origin: center;
                transition: all 75ms $ease;
                border-radius: 50%;
                background-color: rgba($primary, 0.07);
            }

            svg
            {
                path
                {
                    stroke-dasharray: 20;
                    stroke-dashoffset: 20;
                    transition: all 75ms 0ms $ease-sharp;
                }
            }
        }
    }
}
