@import '../../colors.scss';
@import '../../settings.scss';

.dashboard-header
{
    display: flex;
    position: fixed;
    top: 0;
    z-index: 1000;
    left: 0;
    width: 100vw;
    height: 80px;
    padding: 0 1rem;
    justify-content: space-between;
    align-items: center;
    background-color: $white;
    box-shadow: 0 3px 6px rgba($font, 0.1);

    @media (min-width: 1280px)
    {
        padding: 0 4rem;
    }

    svg
    {
        transform: translateY(-3px);
    }
}
