@import '../../colors.scss';
@import '../../settings.scss';

.children-form
{
    width: 592px;
    max-width: calc(100% - 2rem);
    display: grid;
    gap: 1rem;
    margin: 0 auto;
    border-radius: 0.5rem;

    @media (min-width: 768px)
    {
        border: 1px solid $grey-light;
        padding: 2rem;
    }

    h1
    {
        font-size: 1.25rem;
        opacity: 0.87;
        text-transform: capitalize;
    }

    .is-hidden
        {
            display: none !important;
        }
    
    p
    {
        opacity: 0.6;
        line-height: 1.618;
        font-size: 0.875rem;
    }

    div
    {
        display: block;
        width: 100%;

        &.-two-cols
        {
            display: grid;
            grid-template-columns: minmax(1px, 1fr);
            gap: 1.5rem;

            @media (min-width: 768px)
            {
                grid-template-columns: 1fr 1fr;
            }
        }

        &.-children-accounts
        {
            display: grid;
            grid-template-columns: minmax(1px, 1fr);
            gap: 1.5rem;

            @media (min-width: 768px)
            {
                grid-template-columns: 1fr 1fr;
            }
        }

        &.-no-accounts
        {
            display: none;
        }

        &.-flex-end
        {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            flex-flow: row nowrap;
        }
    }

    .g-button
    {
        margin-left: 1rem;
    }

    a:not(.g-button)
    {
        border-radius: 0.5rem;
        border: 1px solid $grey-light;
        height: 52px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        flex-flow: row nowrap;
        color: rgba($font, 0.6);
        transition: all 75ms $ease;
        padding: 0 1rem;
        font-size: 0.875rem;

        &:hover
        {
            color: rgba($font, 0.87);
            border-color: $grey;
            background-color: $grey-hover-opacity;
        }

        &:active
        {
            color: rgba($font, 0.87);
            border-color: $grey;
            background-color: $grey-active-opacity;
        }

        svg
        {
            margin-right: 1rem;
        }
    }
}
