@import '../../colors.scss';
@import '../../settings.scss';

.login-form
{
    width: calc(100% - 2rem);
    display: grid;
    margin: 0 1rem;
    gap: 1.5rem;
    border-radius: 0.5rem;

    @media (min-width: 768px)
    {
        border: 1px solid $grey-light;
        padding: 2rem;
    }

    @media (min-width: 620px)
    {
        margin: 0 auto;
        width: 420px;
    }

    div
    {
        &.-space-between
        {
            display: flex;
            justify-content: space-between;
            align-items: center;

            a
            {
                font-size: 0.75rem;
                color: rgba($font, 0.6);
                font-weight: $medium;
                transition: all 75ms $ease;

                &:hover
                {
                    color: $primary;
                }
            }
        }
    }
}
