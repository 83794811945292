@import '../../colors.scss';
@import '../../settings.scss';

.poll-content
{
    display: block;
    margin-bottom: 1rem;
    width: 100%;
    height: 320px;
    border: 1px solid $grey-light;
    border-radius: 0.5rem;
    padding: 1rem;
    overflow-y: auto;
    box-shadow: inset 0 0 4px rgba($font, 0.1);

    dl
    {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;

        div
        {
            border: 1px solid $grey-light;
            border-radius: 0.25rem;
            padding: 1rem;
        }

        dt
        {
            color: rgba($font, 0.87);
            font-weight: $medium;
            font-size: 1.125rem;
            line-height: 1.5rem;
            margin-bottom: 0.5rem;
            display: block;
        }

        dd
        {
            display: block;
            color: $primary;
        }
    }
}
