@import '../../../colors.scss';
@import '../../../settings.scss';

.user-data-manager
{
    display: block;
    width: 740px;
    max-width: 100%;
    margin: 4rem auto;
    border: 1px solid $grey-light;
    border-radius: 0.25rem;

    header
    {
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        padding: 1rem;
        border-bottom: 1px solid $grey-light;

        @media (min-width: 768px)
        {
            justify-content: space-between;
            flex-flow: row nowrap;
        }
        
        span
        {
            font-size: 1rem;
            opacity: 0.87;
            font-weight: $medium;
            text-align: left;
            display: block;
            width: 100%;
        }

        div
        {
            line-height: 0;
            display: block;
            width: 100%;
            text-align: left;
            margin-top: 1rem;

            @media (min-width: 768px)
            {
                margin-top: 0;
                text-align: right;
            }
        }

        button
        {
            margin-right: 1rem;

            @media (min-width: 768px)
            {
                margin-right: 0;
                margin-left: 1rem;
            }
        }
    }
}
