@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,900&display=swap');
@import './colors.scss';

*,
*::before,
*::after{
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    border: none;
    outline: none;
    position: relative;
    display: inline-block;
}
html, body, div, span, button, applet, object, input, textarea, select iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed,  figure, figcaption, footer, header, hgroup,  menu, nav, output, ruby, section, summary, time, mark, audio, video {  
    position: relative;
    margin: 0;
    padding: 0;  
    border: 0;
    font-weight: 400;
    font-size: 100%;
    font-family: inherit;  
    vertical-align: baseline; 
    outline: none;
    max-width: 100%;
    box-shadow: none;
    text-decoration: none;
    color: inherit;
    background-color: transparent;
}
body
{
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-weight: 400;
    color: $font;
    background-color: var(--grey-1);
    width: 100vw;
    min-height: 100vh;
    overflow-x: hidden;
}

:root
{
    font-size: 100%;
}

template,
style,
script,
head
{
    display: none !important;
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button,
input[type=number]
{
    -webkit-appearance: none;
    -moz-appearance: textfield;
}
input[type=color]::-webkit-color-swatch-wrapper,
input[type=color]::-webkit-color-swatch
{
    padding: 0;
    border: none;
}
