@import '../../../colors.scss';
@import '../../../settings.scss';

.data-manager-user-switcher
{
    padding: 0 56px 0 0.5rem;

    select
    {
        font-size: 2rem;
        opacity: 0.87;
        background: transparent;
        outline: none;
        border: none;
        box-shadow: none;
        -webkit-appearance: none;
        cursor: pointer;

        &::-ms-expand
        {
            display: none;
        }
    }

    svg
    {
        position: absolute;
        top: 50%;
        right: calc(1rem + 12px);
        transform: translateY(-50%);
        opacity: 0.6;
    }
}
