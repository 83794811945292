@import '../colors.scss';
@import '../settings.scss';

.g-button
{
    display: inline-block;
    height: 36px;
    line-height: 36px;
    border-radius: 0.125rem;
    padding: 0 1rem;
    font-size: 0.875rem;
    font-weight: $medium;
    user-select: none;
    cursor: pointer;
    text-align: center;
    min-width: 76px;
    text-transform: uppercase;
    transition: all 75ms $ease;

    &.-solid
    {
        &.-primary
        {
            border: 1px solid $primary;
            background-color: $primary;
            color: $white;

            &:hover
            {
                border-color: $primary-hover;
                background-color: $primary-hover;
            }

            &:active
            {
                border-color: $primary-active;
                background-color: $primary-active;
            }
        }
    }

    &.-grey
    {
        &.-outline
        {
            border: 1px solid $grey-light;
            color: rgba($font, 0.6);

            &:hover
            {
                background-color: $grey-hover-opacity;
                color: rgba($font, 0.87);
                border-color: $grey;
            }

            &:hover
            {
                background-color: $grey-active-opacity;
                color: rgba($font, 0.87);
                border-color: $grey;
            }
        }

        &.-text
        {
            color: $grey;
            padding: 0 0.5rem;

            &:hover
            {
                background-color: $grey-hover-opacity;
            }

            &:active
            {
                background-color: $grey-active-opacity;
            }
        }
    }


    &.-danger
    {
        &.-solid
        {
            border: 1px solid $danger;
            background-color: $danger;
            color: $white;

            &:hover
            {
                border-color: $danger-hover;
                background-color: $danger-hover;
            }

            &:active
            {
                border-color: $danger-active;
                background-color: $danger-active;
            }
        }

        &.-outline
        {
            border: 1px solid $danger;
            color: rgba($danger, 0.87);

            &:hover
            {
                background-color: $danger-hover-opacity;
                color: rgba($danger, 1);
                border-color: $danger;
            }

            &:active
            {
                background-color: $danger-active-opacity;
                color: rgba($danger, 1);
                border-color: $danger;
            }
        }

        &.-text
        {
            padding: 0 0.5rem;
            color: $danger;

            &:hover
            {
                background-color: $danger-hover-opacity;
            }

            &:active
            {
                background-color: $danger-active-opacity;
            }
        }
    }

    &.is-disabled
    {
        border-color: $off-white !important;
        color: $grey-light !important;
        background-color: $off-white !important;
        cursor: not-allowed !important;
    }
}
