@import '../../colors.scss';
@import '../../settings.scss';

.forgotten-password-form
{
    width: 592px;
    display: grid;
    gap: 1.5rem;
    padding: 2rem;
    border: 1px solid $grey-light;
    border-radius: 0.5rem;

    div
    {
        &.-justify-right
        {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            a,
            button
            {
                margin-left: 1rem;
            }
        }

        a.-button
        {
            display: inline-block;
            height: 36px;
            line-height: 36px;
            border-radius: 0.125rem;
            text-align: center;
            text-transform: uppercase;
            padding: 0 1rem;
            font-size: 0.875rem;
            font-weight: $medium;
            user-select: none;
            cursor: pointer;
            border: 1px solid $grey-light;
            color: rgba($font, 0.6);
            transition: all 75ms $ease;

            &:hover
            {
                border-color: $grey;
                background-color: $grey-hover-opacity;
                color: rgba($font, 0.87);
            }

            &:active
            {
                border-color: $grey;
                background-color: $grey-active-opacity;
                color: rgba($font, 0.87);
            }
        }

        h1
        {
            font-size: 1.25rem;
            font-weight: $medium;
            opacity: 0.87;
        }
    }
}
