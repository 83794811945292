.form-grid
{
    width: 640px;
    max-width: calc(100% - 2rem);
    display: grid;
    gap: 1.5rem;
    grid-template-rows: 1fr;
    margin: 0 auto;

    &.-thin
    {
        width: 480px;
    }

    .-two-cols
    {
        display: grid;
        grid-template-columns: minmax(1px, 1fr);
        gap: 1.5rem;

        @media (min-width: 768px)
        {
            grid-template-columns: 1fr 1fr;
        }
    }

    .-space-between
    {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-flow: row nowrap;
    }

    .-flex-end
    {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-flow: row nowrap;
    }
}
